import { BrowserRouter } from "react-router-dom"
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.scss'

import Router from "./router"

function App() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="875994520632-vt84psl821c2bvhtohdhfq15ssfc4led.apps.googleusercontent.com">
        <Router />
      </GoogleOAuthProvider>
    </BrowserRouter>
  )
}

export default App