import { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { Compound } from '../../models/Compound'
import { Model } from '../../models/Model'

interface ModelInfoCompoundsProps {
  model: Model
}

export const ModelInfoCompounds = (props: ModelInfoCompoundsProps) => {
  const { model } = props
  const [orderBy, setOrderBy] = useState('desc')
  const [sortByAttribute, setSortByAttribute] = useState('importance_factor')
  const [compoundsList, setCompoundsList] = useState<Array<Compound>>([])

  useEffect(() => {
    setCompoundsList(sortResults(model.compounds_list))
  }, [sortByAttribute, orderBy])

  const sortResults = (resultsToSort: Array<Compound>) => {
    let sortedList = []
    sortedList = [...resultsToSort].sort((a, b) => {
      try {
        if (orderBy === 'asc') {
          switch (sortByAttribute) {
            case 'importance_factor':
              return a.importance_factor - b.importance_factor
            case 'mol_weight':
              return a.mol_weight - b.mol_weight
            case 'retention_time':
              return a.retention_time - b.retention_time
            case 'charge_mass_ratio':
              return a.charge_mass_ratio - b.charge_mass_ratio
            case 'kegg_id':
              return a.kegg_id && b.kegg_id ? a.kegg_id.localeCompare(b.kegg_id) : 0
            case 'full_name':
              return a.full_name && b.full_name ? a.full_name.localeCompare(b.full_name) : 0
            default:
              return 0
          }
        } else {
          switch (sortByAttribute) {
            case 'importance_factor':
              return b.importance_factor - a.importance_factor
            case 'mol_weight':
              return b.mol_weight - a.mol_weight
            case 'retention_time':
              return b.retention_time - a.retention_time
            case 'charge_mass_ratio':
              return b.charge_mass_ratio - a.charge_mass_ratio
            case 'kegg_id':
              return a.kegg_id && b.kegg_id ? b.kegg_id.localeCompare(a.kegg_id) : 0
            case 'full_name':
              return a.full_name && b.full_name ? b.full_name.localeCompare(a.full_name) : 0
            default:
              return 0
          }
        }
      } catch (e) {
        return 0
      }
    })

    return sortedList
  }

  const CompoundsTable = () => {
    if (model.compounds_list.length === 0) {
      return <>No data to display</>
    } else {
      return (
        <Table hover>
          <thead>
            <tr>
              <th>KEGG ID</th>
              <th>Full Name</th>
              <th>Importance Factor</th>
              <th>Molecular Weight</th>
              <th>Retention time (s)</th>
              <th>Charge/Mass Ratio</th>
            </tr>
          </thead>
          <tbody>
            {compoundsList.map((row: Compound) => {
              return (
                <tr key={row.mol_weight} className={row.kegg_id ? 'clickable' : ''} onClick={() => row.kegg_id ? window.open(`https://www.genome.jp/dbget-bin/www_bget?${row.kegg_id}`, "_blank", "noreferrer") : null}>
                  <td>{row.kegg_id ? row.kegg_id : 'N/A'}</td>
                  <td>{row.full_name ? row.full_name : 'Unknown'}</td>
                  <td>{row.importance_factor}</td>
                  <td>{row.mol_weight}</td>
                  <td>{row.retention_time}</td>
                  <td>{row.charge_mass_ratio}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    }
  }

  return (
    <>
      <h1>Compounds List</h1>
      <div className='section'>
        <div className='card'>
          <div className="card-body">
            <div className='card-filters'>
              <div><strong>Order By :</strong></div>

              <Form.Select id='compounds_sort_by_attribute' onChange={e => { setSortByAttribute(e.target.value) }}>
                <option value="importance_factor">Importance Factor</option>
                <option value="mol_weight">Molecular Weight</option>
                <option value="retention_time">Retention Time</option>
                <option value="charge_mass_ratio">Charge/Mass Ratio</option>
                <option value="kegg_id">KEGG ID</option>
                <option value="full_name">Full Name</option>
              </Form.Select>

              <Form.Select id='compounds_order_by' onChange={e => { setOrderBy(e.target.value) }}>
                <option value="desc">DESC</option>
                <option value="asc">ASC</option>
              </Form.Select>
            </div>

            <CompoundsTable />
          </div>
        </div>
      </div>
    </>
  )
}