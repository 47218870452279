import { Deployment } from '../../models/Deployment'
import { Model } from '../../models/Model'

interface ModelInfoDescriptionProps {
  deployment: Deployment
  model: Model
}

export const ModelInfoDescription = (props: ModelInfoDescriptionProps) => {
  const { deployment, model } = props

  return (
    <>
      <h1>{model.ionization_column}</h1>

      <div className='section'>

        <div className='card'>
          <div className="card-body">
            <div className='card__header'>
              <h2>Description</h2>
            </div>

            <table>
              <tbody>
                <tr>
                  <td><strong>Analysis Method :</strong></td>
                  <td>{model.analysis_method}</td>
                </tr>
                <tr>
                  <td><strong>Algorithm Type :</strong></td>
                  <td>{deployment.algorithm_type}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='card'>
          <div className="card-body">
            <div className='card__header'>
              <h2>Training Parameters</h2>
            </div>

            <table>
              <tbody>
                <tr>
                  <td><strong>Dataset Size :</strong></td>
                  <td>{model.training_params.dataset_size}</td>
                </tr>
                <tr>
                  <td><strong>Synthetic Dataset Size :</strong></td>
                  <td>{model.training_params.dataset_size_synthetic}</td>
                </tr>
                <tr>
                  <td><strong>Dataset Allotment :</strong></td>
                  <td>Train: {model.training_params.train_test_validate_allotment[0] * 100}%,
                    Test: {model.training_params.train_test_validate_allotment[1] * 100}%,
                    Validate: {model.training_params.train_test_validate_allotment[2] * 100}%</td>
                </tr>
                <tr>
                  <td><strong>Epoch Number :</strong></td>
                  <td>{model.training_params.epoch_number}</td>
                </tr>
                <tr>
                  <td><strong>Early Stopping :</strong></td>
                  <td>{model.training_params.early_stopping}</td>
                </tr>
                <tr>
                  <td><strong>Activation Function :</strong></td>
                  <td><div className='code'>{model.training_params.activation_function}</div></td>
                </tr>
                <tr>
                  <td><strong>MSE Loss Criteria :</strong></td>
                  <td><div className='code'>{model.training_params.mse_loss_criteria}</div></td>
                </tr>
                <tr>
                  <td><strong>Optimization Algorithm :</strong></td>
                  <td><div className='code'>{model.training_params.optimization_algorithm}</div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}