import { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'
import { Deployment } from '../../models/Deployment'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

const axios = require('axios').default
const moment = require('moment')

export const DeploymentInfo = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [deployment, setDeployment] = useState<Deployment>()

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  useEffect(() => {
    api.get(params.deployment_id, { headers: { 'Authorization': localStorage.getItem('credential') } })
      .then(function (response: any) {
        setDeployment(response.data)
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  if (deployment === undefined) {
    return <LoadingSpinner />
  }

  const BreadcrumbComponent = () => (
    <Breadcrumb>
      <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>Home</Breadcrumb.Item>
      <Breadcrumb.Item active>{moment.utc(deployment.created_at).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</Breadcrumb.Item>
    </Breadcrumb>
  )

  return (
    <div className='content'>
      <BreadcrumbComponent />

      <h1>Deployment of {moment.utc(deployment.created_at).local().format(process.env.REACT_APP_DATETIME_FORMAT)} ({moment.utc(deployment.created_at).local().fromNow()})</h1>

      <div className='section'>
        <div className='card'>
          <div className="card-body">
            <div className='card__header'>
              <h2>Information</h2>
            </div>

            <div><strong>Environment :</strong> {deployment.environment}</div>
            <div><strong>Algorithm Type :</strong> {deployment.algorithm_type}</div>
            <br />

            <a href={'https://console.aws.com'} className='btn btn-primary disabled'>View on AWS Console</a>&nbsp;
            <button className='btn btn-danger disabled'>Rollback</button>
          </div>
        </div>
      </div>

      <br />
      <h1>Embedded Models</h1>

      <div className='section'>

        {deployment.models && deployment.models.map((row) => {
          return (
            <div key={row.ionization_column} id={row.ionization_column} className='card clickable' onClick={() => navigate(row.ionization_column, { state: { deployment, model: row } })}>
              <div className="card-body">
                <div className='card__header'>
                  <h2>{row.ionization_column}</h2>
                </div>

                <div><strong>Accuracy :</strong> {row.metrics.accuracy}%</div>
              </div>
            </div >
          )
        })}
      </div>
    </div>
  )
}