import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { useGlobalState } from '../../providers/GlobalState'
import './AlertMessage.scss'

export const AlertMessage = () => {
  const [globalState, setGlobalState] = useGlobalState()
  const { alertMessage } = globalState

  return (
    <ToastContainer className="p-3" position={'top-center'}>
      <Toast className='alert-message--success' onClose={() => setGlobalState({ alertMessage: undefined })} show={alertMessage !== undefined} delay={6000} autohide>
        <Toast.Body>{alertMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}