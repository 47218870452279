import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { Deployment } from '../../models/Deployment'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

const axios = require('axios').default
const moment = require('moment')

export const Home = () => {
  const navigate = useNavigate()
  const [latestDeployments, setLatestDeployments] = useState<Array<Deployment>>([])

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  useEffect(() => {
    api.get('/', { headers: { 'Authorization': localStorage.getItem('credential') } })
      .then(function (response: any) {
        setLatestDeployments(response.data)
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  const History = () => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>Deployed on</th>
            <th>Status</th>
            <th>Worst Model Accuracy</th>
            <th>Best Model Accuracy</th>
          </tr>
        </thead>
        <tbody>
          {latestDeployments && latestDeployments.map((row) => {
            return (
              <tr key={row.id} className='clickable' onClick={() => navigate(row.id)}>
                <td>{moment.utc(row.created_at).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
                <td>Active</td>
                <td>{Math.min(...row.models.map(o => o.metrics.accuracy))}%</td>
                <td>{Math.max(...row.models.map(o => o.metrics.accuracy))}%</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  if (latestDeployments.length === 0) {
    return <LoadingSpinner />
  }

  return (
    <div className='content'>
      <h1>Home</h1>

      <div className='section'>

        <div className='card clickable' onClick={() => navigate(latestDeployments[0].id)}>

          <div className="card-body">
            <div className='card__header'>
              <h2>Latest deployment ({moment.utc(latestDeployments[0].created_at).local().fromNow()})</h2>
            </div>

            <div><strong>Deployed at :</strong> {moment.utc(latestDeployments[0].created_at).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</div>
            <div><strong>Ping :</strong> 10ms (10 minutes ago)</div>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='card'>
          <div className="card-body">
            <div className='card__header'>
              <h2>History</h2>
            </div>

            <History />
          </div>
        </div>
      </div>
    </div>
  )
}