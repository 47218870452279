import { Breadcrumb } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Deployment } from '../../models/Deployment'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { Model } from '../../models/Model'
import { ModelInfoCompounds } from '../ModelInfoCompounds/ModelInfoCompounds'
import { ModelInfoPerformanceMetrics } from '../ModelInfoPerformanceMetrics/ModelInfoPerformanceMetrics'
import { ModelInfoDescription } from '../ModelInfoInDescription/ModelInfoInDescription'

const moment = require('moment')

interface LocationState {
  deployment: Deployment
  model: Model
}

export const ModelInfo = () => {
  const { deployment, model } = useLocation().state as LocationState

  const BreadcrumbComponent = () => (
    <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item linkProps={{ to: `/${deployment.id}` }} linkAs={Link}>{moment.utc(deployment.created_at).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</Breadcrumb.Item>
      <Breadcrumb.Item active>{model.ionization_column}</Breadcrumb.Item>
    </Breadcrumb>
  )

  if (deployment === undefined) {
    return <LoadingSpinner />
  }

  return (
    <div className='content'>
      <BreadcrumbComponent />

      <ModelInfoDescription model={model} deployment={deployment} />

      <ModelInfoPerformanceMetrics model={model} />

      <ModelInfoCompounds model={model} />
    </div>
  )
}