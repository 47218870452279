import { Link } from "react-router-dom"
import { ReactComponent as Logo } from './../../assets/logo-text.svg'
import './AppLogo.scss'

export const AppLogo = () => {
  return (
    <Link to={'/'}>
      <Logo className='app-logo' />
    </Link>
  )
}