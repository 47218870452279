import { createContext, useContext, useEffect } from 'react'
import { useReducer } from '../utils/hooks'

export const GlobalStateContext = createContext<any>({})

const GlobalStateProvider = ({ children, ...rest }: any) => {
  const initialState = {
    alertMessage: undefined,
    credential: undefined
  }

  const [globalState, setGlobalState] = useReducer(initialState)

  useEffect(() => {
    checkLocalStorage()
  }, [])

  function checkLocalStorage() {
    const credential = localStorage.getItem('credential')
    credential && setGlobalState({ credential })
  }

  return (
    <GlobalStateContext.Provider value={[globalState, setGlobalState]} {...rest}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalStateContext)

export default GlobalStateProvider
