import ReactDOM from 'react-dom/client'
import GlobalStateProvider from './providers/GlobalState'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'simple-css-reset/reset.css'
import App from './App'
import './index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <GlobalStateProvider children={<App />} />
)