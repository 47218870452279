import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Model } from '../../models/Model'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface ModelInfoPerformanceMetricsProps {
  model: Model
}

interface BarChartProps {
  title: string
  value: number
  unit_of_measure?: string
}

export const ModelInfoPerformanceMetrics = (props: ModelInfoPerformanceMetricsProps) => {
  const { model } = props

  const BarChart = ({ title, value, unit_of_measure }: BarChartProps) => {
    const labels = ['']
    const options = {
      responsive: true,
      scales: {
        y:
        {
          min: 0,
          max: 100
        }
      }
    }
    const data = {
      labels,
      datasets: [
        {
          label: title,
          data: [value],
          backgroundColor: 'rgba(255, 99, 132, 1)',
        }
      ]
    }

    return value ? (
      <div className='card'>
        <div className="card-body">
          <div className='card__header'>
            <h2>{title}</h2>
          </div>

          <div><strong>{value}{unit_of_measure}</strong></div>

          <Bar options={options} data={data} />
        </div>
      </div>
    ) : <></>
  }

  const AreaUnderCurveGraph = () => {
    return model.metrics.area_under_curve ? (
      <div className='card'>
        <div className="card-body">
          <div className='card__header'>
            <h2>Area Under Curve (ROC)</h2>
          </div>
        </div>
      </div>
    ) : <></>
  }

  return (
    <>
      <h1>Performance Metrics</h1>

      <div className='section'>
        <BarChart title='Accuracy' value={model.metrics.accuracy} unit_of_measure='%' />
        {model.metrics.mean_squared_error && <BarChart title='Mean Squared Error' value={model.metrics.mean_squared_error} />}
        {model.metrics.root_mean_squared_error && <BarChart title='Root Mean Squared Error' value={model.metrics.root_mean_squared_error} />}

        <AreaUnderCurveGraph />
      </div>
    </>
  )
}