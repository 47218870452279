import Spinner from 'react-bootstrap/Spinner'
import './LoadingSpinner.scss'

export const LoadingSpinner = () => {

  return (
    <div className='loading-spinner'>
      <Spinner animation="border" />
    </div>
  )
}