import { Navigate, Route, Routes } from 'react-router-dom'
import { AlertMessage } from '../components/AlertMessage/AlertMessage'
import { DeploymentInfo } from '../components/DeploymentInfo/DeploymentInfo'
import { Header } from '../components/Header/Header'
import { Home } from '../components/Home/Home'
import { Login } from '../components/Login/Login'
import { ModelInfo } from '../components/ModelInfo/ModelInfo'
import { NavBar } from '../components/NavBar/NavBar'
import { useGlobalState } from '../providers/GlobalState'

const Router = () => {
  const [globalState, setGlobalState] = useGlobalState()
  const { credential } = globalState

  const UserLayout = () => {
    return (
      <div className="app">
        <NavBar />

        <div className='app__body'>
          <AlertMessage />

          <Header />

          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path=":deployment_id" element={<DeploymentInfo />} />
              <Route path=":deployment_id/:ionization_column" element={<ModelInfo />} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    )
  }

  const GuestLayout = () => {
    return (
      <div className="app">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    )
  }

  return credential ? <UserLayout /> : <GuestLayout />
}

export default Router
