import Button from 'react-bootstrap/Button'
import { useGlobalState } from '../../providers/GlobalState'
import './Header.scss'

export const Header = () => {
  const [globalState, setGlobalState] = useGlobalState()

  const signOut = () => {
    localStorage.removeItem('credential')
    setGlobalState({ credential: undefined })
  }

  return (
    <div className='header'>
      <Button variant="link" onClick={signOut}>Sign out</Button>
    </div>
  )
}