import { Nav } from 'react-bootstrap'
import { AppLogo } from '../AppLogo/AppLogo'
import { NavBarItem } from '../NavBarItem/NavBarItem'
import { ReactComponent as IconDashboard } from './../../assets/icon-dashboard.svg'
import './NavBar.scss'

export const NavBar = () => {
  return (
    <aside className='navbar'>
      <AppLogo />

      <Nav defaultActiveKey="/" className="nav__list">
        <NavBarItem text='Home' to='/' icon={IconDashboard} />
      </Nav>
    </aside>
  )
}