import { GoogleLogin } from '@react-oauth/google'
import { ReactComponent as Logo } from './../../assets/logo-text.svg'
import { useGlobalState } from '../../providers/GlobalState'
import './Login.scss'

export const Login = () => {
  const [globalState, setGlobalState] = useGlobalState()

  return (
    <div className='card login-card'>
      <div className="card-body">
        <div className='content centered'>
          <div>
            <Logo width={200} />

            <div className='by-alleviate'>
              <span>By</span>
              <img src="alleviate-logo-text.png" width={100} alt="Alleviate Logo" />
            </div>

          </div>

          <h1>Sign in</h1>

          <div>Use your Google Account</div>

          <GoogleLogin
            onSuccess={credentialResponse => {
              localStorage.setItem('credential', credentialResponse.credential!)
              setGlobalState({ credential: credentialResponse.credential })
            }}
            auto_select
          />
        </div>
      </div>
    </div>
  )
}